import React, {Component} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Image from 'react-bootstrap/Image';
import { handleLogout } from '../auth/AuthHandlers';

class HeaderProfileUser extends Component {

    handleLogoutClick = handleLogout;

    render(){
        return (
            <Dropdown>
                <Dropdown.Toggle className="header-item waves-effect" id="page-header-user-dropdown" bsPrefix="toggle">
                    <Image className="header-profile-user" src="assets/images/default/avatar-male-sm.png" roundedCircle />
                    <span className="d-none d-sm-inline-block ml-1">Carlos</span>
                    <i className="mdi mdi-chevron-down d-none d-sm-inline-block"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" className="dropdown-item">
                        <i className="mdi mdi-face-profile font-size-16 align-middle mr-1"></i> Profile
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="dropdown-item">
                        <i className="mdi mdi-credit-card-outline font-size-16 align-middle mr-1"></i> Billing
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3" className="dropdown-item">
                        <i className="mdi mdi-account-settings font-size-16 align-middle mr-1"></i> Settings
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-4" className="dropdown-item">
                        <i className="mdi mdi-lock font-size-16 align-middle mr-1"></i> Lock screen
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item href="#/action-5" className="dropdown-item" onClick={handleLogout}>
                        <i className="mdi mdi-logout font-size-16 align-middle mr-1"></i> Logout
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          );
    }
}

export default HeaderProfileUser;