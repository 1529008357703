import React, {Component} from 'react';
import PageTitlePanel from '../primitives/PageTitlePanel';
import SettingsButton from '../primitives/SettingsButton';
import Dashboard from '../pages/Dashboard';
import '../../css/MainContent.css';

class MainContent extends Component {

    render(){
        return (
            <div className="main-content">
                <div className="page-content">
                    <div className="page-title-box">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-8">
                                    <PageTitlePanel />
                                </div>
                                <div className="col-md-4">
                                    <div className="float-right d-none d-md-block">
                                        <SettingsButton />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-content-wrapper">
                        {this.props.children}
                    </div>
                </div>
            </div>
          );
    }
}

export default MainContent;