import React from 'react';
import ReactDOM from 'react-dom';
import { User } from 'oidc-client';
import App from './App';
import { handleAzureIDServerCallback } from './components/auth/AuthHandlers';
import './index.css';

console.log("index");
handleAzureIDServerCallback(startApp, handleError);

async function handleError() {
  await startApp(undefined);
}

async function startApp(user?: User) {
  const PageCannotBeDisplayed = () => {
    return <div></div>;
  };

  try {
    ReactDOM.render(user ? <App authenticatedUser={user} /> : PageCannotBeDisplayed(), document.getElementById('root'));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
