import React from 'react';
import { Link } from 'react-router-dom';

type MenuItemProps = {
    text: string,
    url: string,
    iconClass: string
}

const MenuItem = ({text, url, iconClass}: MenuItemProps) => {
    return(
        <li>
            <Link to={url} className="waves-effect">
            <div className="d-inline-block icons-sm mr-1"><i className={iconClass}></i></div>
                <span>{text}</span>
            </Link>
        </li>
    );
}

export default MenuItem;