const deepLinkKey = 'deepLink';

export const storeDeepLink = () => {
  const deepLink = window.location.pathname;
  if (deepLink) {
    window.sessionStorage.setItem(deepLinkKey, deepLink);
  }
};

export const retrieveDeepLink = () => {
  return window.sessionStorage.getItem(deepLinkKey);
};

export const clearDeepLink = () => {
  window.sessionStorage.removeItem(deepLinkKey);
};
