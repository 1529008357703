import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  client_id: 'jobRelatedPortal',
  redirect_uri: process.env.REACT_APP_IdentityServer_Redirect_Uri,
  post_logout_redirect_uri: process.env.REACT_APP_IdentityServer_Post_Logout_Redirect_Uri,
  response_type: 'token id_token',
  scope: 'openid profile GatewayApi',
  authority: process.env.REACT_APP_IdentityServer_Authority,
  silent_redirect_uri: process.env.REACT_APP_IdentityServer_Silent_Redirect_Uri,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;