import React, {Component} from 'react';
import MyResume from '../cards/MyResume';

class Resume extends Component {

    render(){
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <MyResume />
                    </div>
                </div>
            </div>
          );
    }
}

export default Resume; 