import React, {Component} from 'react';
import MenuTitle from '../primitives/MenuTitle';
import MenuItem from '../primitives/MenuItem';

interface MenuType {
    typeName: string;
}

class SideMenu extends Component<MenuType> {



    render(){
        return (
            <React.Fragment>
                <MenuTitle text={this.props.typeName} />
                <MenuItem text="Dashboard" url="/" iconClass="mdi mdi-view-dashboard" />
                <MenuItem text="My Resume" url="MyResume" iconClass="mdi mdi-file-document" />
                <MenuItem text="Communications" url="Communications" iconClass="mdi mdi-message-text-outline" />
            </React.Fragment>
        );
    }
}

export default SideMenu;