import React from 'react';

const LogoMain = () => {
    return(
        <div className="navbar-brand-box">
            <a href="index.html" className="logo logo-dark">
                <span className="logo-lg">
                    <img src="assets/images/logoDark.svg" alt="" height="60" />
                </span>
            </a>
        </div>
    );
}

export default LogoMain;