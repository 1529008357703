import React from 'react';
import { User } from 'oidc-client';
import { Route, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';

import store from './components/infrastructure/store';
import userManager from './components/auth/UserManager';
import Header from './components/partials/Header';
import SideNav from './components/partials/SideNav';
import MainContent from './components/partials/MainContent';
import Dashboard from './components/pages/Dashboard';
import Resume from './components/pages/Resume';
import Communications from './components/pages/Communications';


interface AppProps {
  authenticatedUser: User;
}

function App(AppProps: any) {
  
  return (
    <ReduxProvider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <div className="layout-wrapper">
        <Router>
          <Header />
          <SideNav />  
          <MainContent>
            <Switch>
              <Route path="/" component={Dashboard} exact />
              <Route path="/myresume" component={Resume} exact />
              <Route path="/communications" component={Communications} exact />
            </Switch>
          </MainContent>
        </Router>        
        </div>
      </OidcProvider>
    </ReduxProvider>
  );
}

export default App;
