import React, {Component} from 'react';

class Communications extends Component {

    render(){
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        oh la la! my Communications
                    </div>
                </div>
            </div>
          );
    }
}

export default Communications; 