import React, {Component} from 'react';

class SettingsButton extends Component {

    render(){
        return (
            <div className="dropdown">
                <button className="btn btn-light btn-rounded dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="mdi mdi-settings-outline mr-1"></i> Settings
                </button>
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated">
                    <a className="dropdown-item" href="#">Action</a>
                    <a className="dropdown-item" href="#">Another action</a>
                    <a className="dropdown-item" href="#">Something else here</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#">Separated link</a>
                </div>
            </div>
          );
    }
}

export default SettingsButton;