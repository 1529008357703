import React, {Component} from 'react';

class MyResume extends Component {

    render(){
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="text-muted">Carlos Acero</p>
                            <p>Software Developer at Kolmeo</p>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default MyResume; 