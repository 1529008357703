import React, {Component} from 'react';
import LogoMain from '../primitives/LogoMain';
import LanguageSelector from '../primitives/LanguageSelector';
import HeaderProfileUser from './HeaderProfileUser';

class Header extends Component {

    render(){
        return (
          <div id="page-topbar">
            <div className="navbar-header">
              <div className="d-flex">
                <LogoMain />

                <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                    <i className="mdi mdi-backburger"></i>
                </button>
              </div>
              <div className="d-flex">
                <LanguageSelector />
                

                <div className="dropdown d-inline-block">
                    <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                        <i className="mdi mdi-tune"></i>
                    </button>
                </div>

                <HeaderProfileUser />
              </div>
            </div>
          </div>
          );
    }
}

export default Header;