import React, {Component} from 'react';
import SideMenu from './SideMenu';

class SideNav extends Component {

    render(){
        return (
            <div className="vertical-menu">
                <div className="h-100">
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <SideMenu typeName="Menu"/>
                        </ul>
                    </div>
                </div>
            </div>
          );
    }
}

export default SideNav;