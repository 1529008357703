import React from "react";
import { connect } from "react-redux";
import WelcomeBack from '../cards/WelcomeBack';
import userManager from '../auth/UserManager';

function Dashboard(props: any){
    const { user } = props;



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-4">
                { !user || user.expired ? (
                <div>
                    <span>You have not authenticated.</span>
                    <button onClick={onLoginButtonClick}>Login</button>
                </div>
                ) : (<WelcomeBack />) }
                </div>
                <div className="col-xl-8">

                </div>
            </div>
        </div>
      );
}

function onLoginButtonClick(event: any) {
    event.preventDefault();
    userManager.signinRedirect();
  }

function mapStateToProps(state: any) {
    return {
      user: state.oidc.user
    };
  }
  
  function mapDispatchToProps(dispatch: any) {
    return {
      dispatch
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
