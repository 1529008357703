import React, {Component} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

class LanguageSelector extends Component {

    render(){
        return (
            <Dropdown>
                <Dropdown.Toggle className="header-item waves-effect" id="page-header-flag-dropdown" bsPrefix="toggle">
                    <img className="" src="assets/images/flags/us.jpg" alt="Header Language" height="14" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1" className="dropdown-item notify-item">
                        <img src="assets/images/flags/spain.jpg" className="mr-2" height="12" /><span className="align-middle">Spanish</span>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="dropdown-item notify-item">
                        <img src="assets/images/flags/italy.jpg" className="mr-2" height="12" /><span className="align-middle">Italian</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
          );
    }
}

export default LanguageSelector;