import { createStore, applyMiddleware, compose } from 'redux';
import { loadUser } from 'redux-oidc';
import rootReducer from './rootReducer';
import userManager from '../auth/UserManager';

const initialState = {
     oidc: undefined
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, initialState);
loadUser(store, userManager);

export default store;