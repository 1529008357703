import React, {Component} from 'react';
import '../../css/PageTitle.css'

class PageTitlePanel extends Component {

    render(){
        return (
            <div className="page-title-panel">
                <h4 className="page-title mb-1">Dashboard</h4>
                <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">Welcome to jobrelated.co Dashboard</li>
                </ol>
            </div>
          );
    }
}

export default PageTitlePanel;