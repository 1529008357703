import React, {Component} from 'react';

class WelcomeBack extends Component {


    render(){
        return (
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <h5>Welcome Back !</h5>
                            <p className="text-muted">Carlos Acero</p>

                            <div className="mt-4">
                                <a href="#" className="btn btn-primary btn-sm">View more <i className="mdi mdi-arrow-right ml-1"></i></a>
                            </div>
                        </div>

                        <div className="col-5 ml-auto">
                            <div>
                                <img src="assets/images/widget-img.png" alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default WelcomeBack; 