import { User } from 'oidc-client';
import { userFound } from 'redux-oidc';
import store from '../infrastructure/store';
import userManager from './UserManager';
import { retrieveDeepLink, storeDeepLink } from '../infrastructure/DeepLink';

export const handleAzureIDServerCallback = async (onSuccess: (user?: User) => void, onError: () => void) => {
    await userManager.clearStaleState();
    const hash = window.location.hash;
    console.log("entro");
    console.log(window.location);
    if (window.location.pathname.startsWith('/callback')) {
      signinRedirectCallback().then(onSuccess).catch(onError);
    } else {
      ensureUserLoggedIn().then(onSuccess).catch(onError);
    }
  };

  const signinRedirectCallback = async () => {
    try {
      const user = await userManager.signinRedirectCallback();
      const deepLink = retrieveDeepLink();
      window.history.replaceState(null, document.title, deepLink || '/');
      if (user) {
        store.dispatch(userFound(user));
      }
      console.log(user);
      return user;
    } catch (e) {
      console.error(e);
    }
  };

  const ensureUserLoggedIn = async () => {
    try {
      const user = await userManager.getUser();
  
      //user.expired check for the access_token expiry and not the id_token. a custom check needed
      if (user) {//if (user && !isExpired(user.id_token)) {
      //  trackTokenExpiration(user.id_token);
        return user;
      } else {
        console.log('user not found - redirect to login');
        storeDeepLink();
        await userManager.signinRedirect();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  
    throw new Error('Unable to log in user');
  };

export const handleLogout = async () => {
    const user = await userManager.getUser();
    await userManager.signoutRedirect({ 'id_token_hint': user?.id_token });
 };